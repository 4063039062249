import React from "react"

export default class MeetOurGuide 
{
    static person=[
        {
            "name":"Full Name",
            "designation":"Designation",
            "image":"assets/img/team-1.jpg",
            "facebook":"#",
            "twitter":"#",
            "instagram":"#"
        },
        {
            "name":"Full Name",
            "designation":"Designation",
            "image":"assets/img/team-2.jpg",
            "facebook":"#",
            "twitter":"#",
            "instagram":"#"
        },
        {
            "name":"Full Name",
            "designation":"Designation",
            "image":"assets/img/team-3.jpg",
            "facebook":"#",
            "twitter":"#",
            "instagram":"#"
        },
        {
            "name":"Full Name",
            "designation":"Designation",
            "image":"assets/img/team-4.jpg",
            "facebook":"#",
            "twitter":"#",
            "instagram":"#"
        }
    ]
    static render(){
        return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Travel Guide
          </h6>
          <h1 className="mb-5">Meet Our Guide</h1>
        </div>
        <div className="row g-4">
          
            {
                this.person.map((person)=>{
                    return (
                      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item">
                            <div className="overflow-hidden">
                                <img className="img-fluid" src={person.image} alt="" />
                            </div>
                            <div
                                className="position-relative d-flex justify-content-center"
                                style={{ marginTop: "-19px" }}
                            >
                                <a className="btn btn-square mx-1" href={person.facebook}>
                                    <i className="fab fa-facebook-f" />
                                </a>
                                <a className="btn btn-square mx-1" href={person.twitter}>
                                    <i className="fab fa-twitter" />
                                </a>
                                <a className="btn btn-square mx-1" href={person.instagram}>
                                    <i className="fab fa-instagram" />
                                </a>
                            </div>
                            <div className="text-center p-4">
                                <h5 className="mb-0">{person.name}</h5>
                                <small>{person.designation}</small>
                            </div>
                        </div>
                    </div>
                    )
                })
            }
          </div>
          
      </div>
    </div>
    )
        }
}