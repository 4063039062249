import React from 'react'
import MeetOurGuide from '../components/MeetOurGuide'
import Config from '../Config'
import AboutUsDescriptionBox from '../components/AboutUsDescriptionBox'
function About() {
  return (
    <div>
        <div className="container-fluid bg-primary py-5 mb-5 hero-header">
    <div className="container py-5">
      <div className="row justify-content-center py-5">
        <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
          <h1 className="display-3 text-white animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">
                <a href="/Pages">Pages</a>
              </li> */}
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                About
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
        
            {/* About Start */}
            {AboutUsDescriptionBox.render()}
    {/* About End */}
    {/* Team Start */}
    {
      MeetOurGuide.render()
    }
    {/* Team End */}
    </div>
  )
}

export default About