import React from 'react'
import { Link } from 'react-router-dom'
import Config from '../Config'
function Header() {
  return (
    <div>
         {/* Spinner Start */}
    {/* <div
      id="spinner"
      className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div
        className="spinner-border text-primary"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div> */}
    {/* Spinner End */}

    {/* Topbar Start */}
    <div className="container-fluid bg-dark px-5 d-none d-lg-block">
      <div className="row gx-0">
        <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: 45 }}
          >
            <small className="me-3 text-light">
              <i className="fa fa-map-marker-alt me-2" />
              {Config.Address}
            </small>
            <small className="me-3 text-light">
              <i className="fa fa-phone-alt me-2" />
              {Config.Phone}
            </small>
            <small className="text-light">
              <i className="fa fa-envelope-open me-2" />
              {Config.Email}
            </small>
          </div>
        </div>
        <div className="col-lg-4 text-center text-lg-end">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: 45 }}
          >
            {
                Config.twitter!=""?
                  <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href={Config.twitter}>
                <i className="fab fa-twitter" />
                  </a>
                  :<></>
              }
              {
                Config.facebook!=""?
                  <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href={Config.facebook}>
                <i className="fab fa-facebook-f" />
                  </a>
                  :<></>
              }
              {
                Config.instagram!=""?
                  <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href={Config.instagram}>
                <i className="fab fa-instagram" />
                  </a>:
                  <></>
              }
              {
                Config.linkedin!=""?
                  <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href={Config.linkedin}>
                <i className="fab fa-linkedin-in" />
                  </a>:
                  <></>
              }
              {
                Config.youtube!=""?
                  <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href={Config.youtube}>
                <i className="fab fa-youtube" />
                  </a>:
                  <></>
              }
          </div>
        </div>
      </div>
    </div>
    {/* Topbar End */}
    {/* Navbar & Hero Start */}
    <div className="container-fluid position-relative p-0">
      <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <Link href="/" className="navbar-brand p-0">
          <h1 className="text-primary m-0">
            <i className="fa fa-map-marker-alt me-3" />
            {Config.companyName}
          </h1>
          {/* <img src="img/logo.png" alt="Logo"> */}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <a href="/" className="nav-item nav-link" >
              Home
            </a>
            <a href="/About" className="nav-item nav-link" >
              About
            </a>
            <a href="/Services" className="nav-item nav-link" >
              Services
            </a>
            <a href="/Packages" className="nav-item nav-link" >
              Packages
            </a>
            {/* <div className="nav-item dropdown">
              <Link
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Pages
              </Link>
              <div className="dropdown-menu m-0">
                <Link href="/Destination" className="dropdown-item">
                  Destination
                </Link>
                <Link href="/Booking" className="dropdown-item">
                  Booking
                </Link>
                <Link href="/Team" className="dropdown-item">
                  Travel Guides
                </Link>
                <Link href="/Testimonial" className="dropdown-item">
                  Testimonial
                </Link>
                <Link href="/Error" className="dropdown-item">
                  404 Page
                </Link>
              </div>
            </div> */}
            <a href="/Contact" className="nav-item nav-link" >
              Contact
            </a>
          </div>
          {/* <Link href="/" className="btn btn-primary rounded-pill py-2 px-4">
            Register
          </Link> */}
        </div>
      </nav>
    </div>
    {/* Navbar & Hero End */}
    </div>
  )
}

export default Header