export default class Config {
    static Address="123 Street, Kowloon, Hong Kong";
    static Phone="12345678";
    static Email="example@example.com";
    static twitter="https://twitter.com";
    static facebook="https://facebook.com";
    static instagram="https://instagram.com";
    static linkedin="https://linkedin.com";
    static youtube="https://youtube.com";
    static companyName="MiSolution Template";
    static GoogleMapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.775364173618!2d114.1917397!3d22.286497599999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040101d6e737bb%3A0x59b88464202cb80!2z6YqF6ZG854GjMTQ46Zu75rCj6YGT!5e0!3m2!1szh-TW!2shk!4v1732677181715!5m2!1szh-TW!2shk";
}