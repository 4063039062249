export default class PackageCard{
    static packageDetails=[
        {
            img:"assets/img/package-1.jpg",
            location:"Thailand",
            days:"3 days",
            person:"2 Person",
            price:"$149.00",
            rating:5,
            description:"Explore the natural beauty of Thailand with our awesome packages, sea , beach and much more",
            detailPageUrl:"#",
            bookNowUrl:"#"
        },
        {
            img:"assets/img/package-2.jpg",
            location:"Indonesia",
            days:"3 days",
            person:"2 Person",
            price:"$139.00",
            rating:5,
            description:"Indonesia is a beautiful country with many islands, explore the beauty of Indonesia with our packages",
            detailPageUrl:"#",
            bookNowUrl:"#"
        },
        {
            img:"assets/img/package-3.jpg",
            location:"Malaysia",
            days:"3 days",
            person:"2 Person",
            price:"$189.00",
            rating:5,
            description:"Join our Malaysia Rafting Adventure tour and experience different levels of rapids",
            detailPageUrl:"#",
            bookNowUrl:"#"
        }
    ]


    static render(){
        return(
        <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Packages
          </h6>
          <h1 className="mb-5">Awesome Packages</h1>
        </div>
        <div className="row g-4 justify-content-center">
         {
                this.packageDetails.map((packageDetail)=>{
                    return (
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="package-item">
              <div className="overflow-hidden">
                <img className="img-fluid" src={packageDetail.img} alt={packageDetail.location+" package"} />
                
              </div>
              <div className="d-flex border-bottom">
                <small className="flex-fill text-center border-end py-2">
                  <i className="fa fa-map-marker-alt text-primary me-2" />
                    {packageDetail.location}
                </small>
                <small className="flex-fill text-center border-end py-2">
                  <i className="fa fa-calendar-alt text-primary me-2" />{
                        packageDetail.days
                  }
                </small>
                <small className="flex-fill text-center py-2">
                  <i className="fa fa-user text-primary me-2" />{
                        packageDetail.person
                  }
                </small>
              </div>
              <div className="text-center p-4">
                <h3 className="mb-0">{
                    packageDetail.price
                }
                </h3>
                <div className="mb-3">
                  <small className="fa fa-star text-primary" />
                  <small className="fa fa-star text-primary" />
                  <small className="fa fa-star text-primary" />
                  <small className="fa fa-star text-primary" />
                  <small className="fa fa-star text-primary" />
                </div>
                <p>
                  {packageDetail.description}
                </p>
                <div className="d-flex justify-content-center mb-2">
                  <a
                    href={packageDetail.detailPageUrl}
                    className="btn btn-sm btn-primary px-3 border-end"
                    style={{ borderRadius: "30px 0 0 30px" }}
                  >
                    Read More
                  </a>
                  <a
                    href={packageDetail.bookNowUrl}
                    className="btn btn-sm btn-primary px-3"
                    style={{ borderRadius: "0 30px 30px 0" }}
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
                    )
                })
         } 

        </div>
        </div>
      </div>

        )
    }
}