import React from 'react'
import PackageCard from '../components/PackageCard'
import BookForm from '../components/BookForm'
import ProcessGuide from '../components/ProcessGuide'

function Packages() {
  return (
    <div>
          <div className="container-fluid bg-primary py-5 mb-5 hero-header">
    <div className="container py-5">
      <div className="row justify-content-center py-5">
        <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
          <h1 className="display-3 text-white animated slideInDown">
            Packages
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Packages
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
      {/* Package Start */}
      {
        PackageCard.render()
      }
    {/* Package End */}
    {/* Booking Start */}
   {BookForm.render()}
    {/* Booking Start */}
    {/* Process Start */}
   {ProcessGuide.render()}
    {/* Process Start */}
    </div>
  )
}

export default Packages