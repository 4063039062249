export default class OurServiceBox 
{
    static service=[
        {
            icon:"fa fa-3x fa-globe text-primary mb-4",
            title:"WorldWide Tours",
            description:"We provide the best tours all over the world"
        },
        {
            icon:"fa fa-3x fa-hotel text-primary mb-4",
            title:"Hotel Reservation",
            description:"Best selling hotels are available for you"
        },
        {
            icon:"fa fa-3x fa-user text-primary mb-4",
            title:"Travel Guides",
            description:"Professional travel guides are available for you"
        },
        {
            icon:"fa fa-3x fa-cog text-primary mb-4",
            title:"Event Management",
            description:"We also provide event management services"
        },
        {
            icon:"fa fa-3x fa-globe text-primary mb-4",
            title:"WorldWide Tours",
            description:"We provide the best tours all over the world"
        },
        {
            icon:"fa fa-3x fa-hotel text-primary mb-4",
            title:"Hotel Reservation",
            description:"Best selling hotels are available for you"
        },
        {
            icon:"fa fa-3x fa-user text-primary mb-4",
            title:"Travel Guides",
            description:"Professional travel guides are available for you"
        },
        {
            icon:"fa fa-3x fa-cog text-primary mb-4",
            title:"Event Management",
            description:"We also provide event management services"
        },
    ]
    
static render(){
    return(<div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Services
          </h6>
          <h1 className="mb-5">Our Services</h1>
        </div>
        <div className="row g-4">
          {
                this.service.map((service)=>{
                    return (
                      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item text-center p-4">
                          <i className={service.icon} />
                          <h5 className="mb-3">{service.title}</h5>
                          <p className="mb-0">{service.description}</p>
                        </div>
                      </div>
                    )
                }
                )
          }
        </div>
      </div>
    </div>
    )
}

}