import React from 'react'
import MeetOurGuide from '../components/MeetOurGuide'
import Config from '../Config'
import AboutUsDescriptionBox from '../components/AboutUsDescriptionBox'
import OurServiceBox from '../components/OurServiceBox'
import DestinationComponent from '../components/DestinationComponent'
import PackageCard from '../components/PackageCard'
import BookForm from '../components/BookForm'
import ProcessGuide from '../components/ProcessGuide'
import TestimonialBox from '../components/TestimonialBox'
export default function Home() {
  return (
    <div>
      <div className="container-fluid bg-primary py-5 mb-5 hero-header">
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white mb-3 animated slideInDown">
                Enjoy Your Vacation With Us
              </h1>
              <p className="fs-4 text-white mb-4 animated slideInDown">
                Explore the world with us and enjoy the best offers
              </p>
              <div className="position-relative w-75 mx-auto animated slideInDown">
                <input
                  className="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Eg: Japan"
                />
                <button
                  type="button"
                  className="btn btn-primary rounded-pill py-2 px-4 position-absolute top-0 end-0 me-2"
                  style={{ marginTop: 7 }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* About Start */}
    {AboutUsDescriptionBox.render()}
    {/* About End */}
    {/* Service Start */}
    {OurServiceBox.render()}
    {/* Service End */}
    {/* Destination Start */}
    {DestinationComponent.render()}
    {/* Destination Start */}
    {/* Package Start */}
    {PackageCard.render()}
    {/* Package End */}
    {/* Booking Start */}
   {BookForm.render()}
    {/* Booking Start */}
    {/* Process Start */}
    {ProcessGuide.render()}
    {/* Process Start */}
    {/* Team Start */}
    {MeetOurGuide.render()}
    {/* Team End */}
    {/* Testimonial Start */}
    {
      TestimonialBox.render()
    }
    {/* Testimonial End */}  
    </div>
  )
}
