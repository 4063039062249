export default class DestinationComponent {

    static destination=[
        {
            img:"assets/img/destination-1.jpg",
            discount:"30% OFF",
            location:"Thailand",
            href:"#"
        },
        {
            img:"assets/img/destination-2.jpg",
            discount:"25% OFF",
            location:"Malaysia",
            href:"#"
        },
        {
            img:"assets/img/destination-3.jpg",
            discount:"35% OFF",
            location:"Australia",
            href:"#"
        },
        {
            img:"assets/img/destination-4.jpg",
            discount:"20% OFF",
            location:"Indonesia",
            href:"#"
        }
    ]


    static render() {
        return (
            <div className="container-xxl py-5 destination">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Destination
          </h6>
          <h1 className="mb-5">Popular Destination</h1>
        </div>
        <div className="row g-3">
          <div className="col-lg-7 col-md-6">
            <div className="row g-3">
              <div
                className="col-lg-12 col-md-12 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <a
                  className="position-relative d-block overflow-hidden"
                  href={this.destination[0].href}
                >
                  <img
                    className="img-fluid"
                    src={this.destination[0].img}
                    alt={this.destination[0].location}
                  />
                  <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                    {this.destination[0].discount}
                  </div>
                  <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                    {this.destination[0].location}
                  </div>
                </a>
              </div>
              <div
                className="col-lg-6 col-md-12 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <a
                  className="position-relative d-block overflow-hidden"
                  href={this.destination[1].href}
                >
                  <img
                    className="img-fluid"
                    src={this.destination[1].img}
                    alt={this.destination[1].location}
                  />
                  <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                    {this.destination[1].discount}
                  </div>
                  <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                    {this.destination[1].location}
                  </div>
                </a>
              </div>
              <div
                className="col-lg-6 col-md-12 wow zoomIn"
                data-wow-delay="0.5s"
              >
                <a
                  className="position-relative d-block overflow-hidden"
                  href={this.destination[2].href}
                >
                  <img
                    className="img-fluid"
                    src={this.destination[2].img}
                    alt={this.destination[2].location}
                  />
                  <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                    {this.destination[2].discount}
                  </div>
                  <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                    {this.destination[2].location}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-6 wow zoomIn"
            data-wow-delay="0.7s"
            style={{ minHeight: 350 }}
          >
            <a
              className="position-relative d-block h-100 overflow-hidden"
              href={this.destination[3].href}
            >
              <img
                className="img-fluid position-absolute w-100 h-100"
                src={this.destination[3].img}
                alt={this.destination[3].location}
                style={{ objectFit: "cover" }}
              />
              <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                {this.destination[3].discount}
              </div>
              <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                {this.destination[3].location}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
        )
    }
}